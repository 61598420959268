<template>
  <div class="container">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "Logout",
  data() {
    return {
      message: "",
      isAuth: this.$store.getters.getIsAuthenticated,
    };
  },
  mounted() {
    if (this.isAuth == false) {
      this.$router.push("/login");
    }
    //   let sendData = {
    //     token: this.verifyid
    //   }
    this.$store
      .dispatch("sendAuthenticatedPost", { endpoint: "/user/logout" })
      .then((response) => {
        if (this.$devmode) console.log("response", response);

        this.message = "You logout";
        this.$store.commit("destroyAuthUser", response.data);

        this.$router.push("/login");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  },
};
</script>